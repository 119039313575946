<template>
  <div class="operate">
    <van-form ref="form" input-align="right" error-message-align="right"  @submit.native.prevent>
      <van-field label="VIN" :value="itemData.vinCode" readonly />
      <van-field :label="$t('车牌')" :value="itemData.carNumber" readonly />
      <!-- 处理结果 -->
      <van-field
        name="radio"
        :label="$t('占用原因')"
        required
        input-align="right"
        error-message-align="right"
        :rules="[{required:true, message:$t('请选择占用原因')}]">
        <template #input>
          <van-checkbox-group
            v-model="itemData.processResult"
            icon-size="32px"
            class="custom-radio-group-wrap">
            <van-checkbox v-for="item in list" :key="item.code" :name="item.code" class="custom-radio-wrap">
              <template #icon="props">
                <div class="custom-radio" :class="{ commonCheck: props.checked }">{{item.name}}</div>
              </template>
            </van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>
      <van-field
          :value="itemData.date | text"
          required
          :label="$t('占用时间')"
          :placeholder="$t('请选择占用时间')"
          right-icon="arrow"
          readonly
          input-align="right"
          :rules="[{required:true}]"
          @click="selectCalendar">
        </van-field>
        <van-field v-model="itemData.processRemark" :label="$t('占用说明')" type="textarea" 
          :placeholder="$t('请输入占用说明')"
          required
          maxlength="100" 
          show-word-limit 
          rows="2"
          autosize
          :rules="[{required:true}]"
          >
        </van-field>
        <div class="common-footer">
          <van-button class="btn back" native-type="button" @click.stop="goBack">{{$t('返回')}}</van-button>
          <van-button class="btn submit" type="submit" @click="onSubmit">{{$t('提交')}}</van-button>
        </div>
    </van-form>
    <CommonDatePicker v-if="showCalendar" :dateType="'datetimerange'" :showTitle="false" @confirm="handleDateTimeConfirm"></CommonDatePicker>
  </div>
  </template>
<script>
import CommonDatePicker from '@/components/v2/system/CommonDatePicker/index.vue'
import { dateFormat } from '@/utils'
import CarManageServices from '@/services/carManageService.js'
import dayjs from 'dayjs'

let vm
export default {
  components:{ CommonDatePicker },
  filters:{
    text:(val)=>{
      const { start,end } =val
      if (!start||!end) return ''
      else return `${dateFormat(start,'YYYY-MM-DD')} ${dateFormat(start,'HH:mm')} ${vm.$t('至')} ${dateFormat(end,'YYYY-MM-DD')} ${dateFormat(end,'HH:mm')}`
    }
  },
  data(){
    return {
      itemData:{
        id:'',
        vinCode:'',
        carNumber:'',
        processResult:[],
        processRemark:'',
        processStartTime: '',
        processEndTime: '',
        date:{
          start:'',
          end:''
        }
      },
      showCalendar: false,
    }
  },
  computed:{
    list(){
      const dictList = this.$store.getters.getDictHash('2007')
      return dictList.map((item)=>({
        name:item.name,
        code:item.code,
        checked:false
      }))
    }
  },
  mounted(){
    const { id,vinCode,carNumber } = this.$route.query
    this.itemData.id =id
    this.itemData.vinCode = vinCode
    this.itemData.carNumber =carNumber
  },
  created(){
    vm = this
  },
  methods:{
    selectCalendar(){
      this.showCalendar = true
    },
    handleDateTimeConfirm(timeInfo){
      const { dateTimes:[start, end] } = timeInfo
      // 判断结束时间是否大于开始时间
      if (dayjs(end).isBefore(dayjs(start),'minute') || dayjs(end).isSame(dayjs(start),'minute')){
        return this.$toast(this.$t('请确认，结束时间要大于开始时间'))
      }
      this.showCalendar = false
      this.itemData.date.start = start
      this.itemData.date.end = end
    },
    goBack(){
      this.$router.go(-1)
    },
    async onSubmit(){
      try {
        await this.$refs.form.validate()
        const params = {
          id: this.itemData.id,
          processStartTime: this.itemData.date.start,
          processEndTime: this.itemData.date.end,
          processRemark: this.itemData.processRemark,
          processResult: this.itemData.processResult.join(',')
        }
        const res = await CarManageServices.carOperatePost(params)
        this.goBack()
      } catch (error) {
        console.log(this.$t('报错了'))
      }
    }
  }
}
</script>
    <style lang="less" scoped>
      @import "@/modules/clue/style/commonless.less";
    
      .operate{
        padding: 16px;
      }
      /deep/ .custom-radio-group-wrap {
        margin-top: -10px;
        display: flex;
        flex-wrap: wrap;
        .custom-radio-wrap {
          margin-top: 10px;
          margin-left: 12px;
          .custom-radio {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 8px;
            height: 24px;
            color: rgba(13,23,26,0.45);
            background-color: #F5F5F5;
            border-radius: 3px;
            font-size: 12px;
            // &.checked {
            //   // border: 1px solid @yellow;
            //   // color: @yellow-text
            // }
            &.commonCheck{
              color: #B9921A;
              background: rgba(238,212,132,0.45);
            }
          }
        }
      }
    </style>
    