import { render, staticRenderFns } from "./operate.vue?vue&type=template&id=05471d7e&scoped=true"
import script from "./operate.vue?vue&type=script&lang=js"
export * from "./operate.vue?vue&type=script&lang=js"
import style0 from "./operate.vue?vue&type=style&index=0&id=05471d7e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05471d7e",
  null
  
)

export default component.exports